import { Dialog, DialogContent } from "@moe/oss/ui/dialog";
import { desktopMediaQuery, useMediaQuery } from "@moe/priv/hooks/use-media-query";
import { ModalConfig } from "@moe/priv/types/types";
import EventEmitter from "events";
import { useEffect, useState } from "react";
import { Sheet } from "react-modal-sheet";
import { RemoveScroll } from "react-remove-scroll";

export const modalEE = new EventEmitter();
/**
 * This component wraps the entire component tree and provides modal (desktop) and drawer (mobile) functionality.
 */
export function ModalProvider() {
  const isDektop = useMediaQuery(desktopMediaQuery);
  const [config, setConfig] = useState<ModalConfig>();
  const createModalHandler = (config: any) => setConfig(config);
  const closeModalHandler = () => setConfig(undefined);

  // Using event emitters to create and close modal instead of react context to prevent expensive re-renders
  // This is confusing when it's used liberally, only do this for hot paths.
  useEffect(() => {
    modalEE.on("createModal", createModalHandler);
    modalEE.on("closeModal", closeModalHandler);
    return () => {
      modalEE.off("createModal", createModalHandler);
      modalEE.off("closeModal", closeModalHandler);
    };
  }, []);

  // Desktop regular modal
  if (isDektop) {
    return (
      <>
        <Dialog
          open={!!config}
          onOpenChange={(o) => {
            if (!o) setConfig(undefined);
          }}
        >
          <DialogContent
            className="border-line w-fit max-w-none border p-0 outline-none"
            onInteractOutside={(e) => {
              if (!config) return;
              if (config.onInteractOutside) {
                config.onInteractOutside(e);
              }
            }}
          >
            {config?.content}
          </DialogContent>
        </Dialog>
      </>
    );
  }

  // Mobile modal sheet / drawer
  return (
    <>
      <RemoveScroll enabled={!!config}>
        <Sheet
          isOpen={!!config}
          disableScrollLocking={true}
          onClose={() => {
            setConfig(undefined);
          }}
          detent="content-height"
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="bg-float flex w-full items-center justify-center pb-4 pt-4">
                <div className="bg-muted h-2 w-20 rounded-sm"></div>
              </div>
            </Sheet.Header>
            <Sheet.Content className="bg-float" disableDrag={config?.headerDragOnly === true}>
              <Sheet.Scroller>{config?.content}</Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            onTap={() => {
              setConfig(undefined);
            }}
          />
        </Sheet>
      </RemoveScroll>
    </>
  );
}
