import { Chat, ChatInsert, ChatUpdate } from "@moe/priv/model/chat";
import { TablesInsert } from "@moe/priv/types/sb-types";
import { ChatPreview, DeepPartial } from "@moe/priv/types/types";
import { QueryData } from "@supabase/supabase-js";
import { ID } from "@web/accessor/base";
import { sb } from "@web/lib/supabase";
import { Client } from "@web/lib/trpc";

type R = Chat;
type I = ChatInsert;
type U = ChatUpdate;

interface GetPageOptions {
  page: number;
  pageSize: number;
  order: "asc" | "desc";
  filter: {
    searchText: string;
    isStudio: boolean;
  };
}

interface GetPreviewPageOptions {
  page: number;
  pageSize: number;
}

namespace Q {
  export namespace All {
    export const selector = `id, characters(id), persona_id, is_studio, created_at, updated_at, memory`;
    const builder = sb.from("chats").select(selector);
    export type Builder = typeof builder;
    export type Data = QueryData<typeof builder>[number];
  }
}

interface Constructor {
  userID?: string;
  trpc: Client;
}

export class ChatAccessor {
  private readonly userID?: string;
  private readonly trpc: Client;

  constructor({ userID, trpc }: Constructor) {
    this.userID = userID;
    this.trpc = trpc;
  }

  /**
   * Retrieves a chat or a list of chats by their ID.
   *
   * @param id - The ID or an array of IDs of the chats to retrieve.
   * @returns A promise that resolves to the chat or an array of chats with the specified ID(s).
   */
  async getByID(id: ID): Promise<R>;
  async getByID(id: ID[]): Promise<R[]>;
  async getByID(id: ID | ID[]): Promise<R | R[]> {
    const q = sb.from("chats").select(Q.All.selector);
    if (Array.isArray(id)) {
      const { data, error } = await q.in("id", id);
      if (error) throw error;
      return this.processAllQuery(data);
    }
    const { data, error } = await q.eq("id", id).single();
    if (error) throw error;

    return this.processAllQuery(data);
  }

  /**
   * Creates a new chat or multiple chats.
   *
   * @param value - The chat data or an array of chat data to create.
   * @returns A promise that resolves to the created chat(s).
   */
  async create(value: I): Promise<R>;
  async create(value: I[]): Promise<R[]>;
  async create(value: I | I[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map((v) => this.create(v)));
    const chatValue: TablesInsert<"chats"> = {
      persona_id: value.personaID,
      is_studio: value.isStudio ?? false,
      memory: value.memory
    };

    const { data, error } = await sb.from("chats").insert(chatValue).select(Q.All.selector).single();

    if (error) throw error;
    const { error: junctionError } = await sb.from("chats_characters").insert({
      chat_id: data.id,
      character_id: value.charID
    });
    if (junctionError) throw error;

    const raw: Q.All.Data = {
      id: data.id,
      characters: [{ id: value.charID }],
      persona_id: value.personaID,
      is_studio: value.isStudio ?? false,
      created_at: data.created_at,
      updated_at: data.updated_at,
      memory: value.memory ?? ""
    };
    return this.processAllQuery(raw);
  }

  /**
   * Updates an existing chat or multiple chats.
   *
   * @param value - The value(s) to update the chat with
   * @returns A promise that resolves to the updated chat(s).
   */
  async update(value: U): Promise<R>;
  async update(value: U[]): Promise<R[]>;
  async update(value: U | U[]): Promise<R | R[]> {
    if (Array.isArray(value)) return Promise.all(value.map(async (v) => this.update(v)));
    const { data, error } = await sb
      .from("chats")
      .update({
        persona_id: value.personaID,
        memory: value.memory
      })
      .eq("id", value.id)
      .select(Q.All.selector)
      .single();
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Removes a chat or multiple chats.
   *
   * @param id - The ID or an array of IDs of the chats to remove.
   * @returns A promise that resolves when the operation is complete.
   */
  async remove(id: ID | ID[]): Promise<void> {
    const { error } = await sb
      .from("chats")
      .delete()
      .in("id", Array.isArray(id) ? id : [id]);
    if (error) throw error;
  }

  /**
   * Retrieves a page of chats based on the provided options.
   *
   * @param options - The options for pagination, ordering, and filtering.
   * @returns A promise that resolves to an array of chats.
   */
  async getPage(options?: DeepPartial<GetPageOptions>): Promise<R[]> {
    if (!this.userID) throw new Error("User ID is required to get chats.");

    const opt = this.normalizeGetPageOptions(options);
    const q = sb.from("chats").select(Q.All.selector);
    const rangeStart = opt.page * opt.pageSize;
    const rangeEnd = rangeStart + opt.pageSize - 1;

    const ascending = opt.order === "asc";
    q.eq("is_studio", opt.filter.isStudio)
      .order("created_at", { ascending })
      .range(rangeStart, rangeEnd)
      .eq("created_by", this.userID);
    if (opt.filter.searchText) throw new Error("Search text is not implemented yet.");

    const { data, error } = await q;
    if (error) throw error;
    return this.processAllQuery(data);
  }

  /**
   * Retrieves a page of chat previews for the authenticated user.
   *
   * @param page - The page number to retrieve (default is 0).
   * @param pageSize - The number of chat previews to return per page (default is 25).
   * @returns An array of ChatPreview objects.
   */
  async getPreviewPage(options: GetPreviewPageOptions): Promise<ChatPreview[]> {
    return this.trpc.chat.getPreviewPage.query(options);
  }

  private processAllQuery(value: Q.All.Data): R;
  private processAllQuery(value: Q.All.Data[]): R[];
  private processAllQuery(value: Q.All.Data | Q.All.Data[]): R | R[] {
    if (Array.isArray(value)) return value.map((v) => this.processAllQuery(v));

    return {
      id: value.id,
      charID: value.characters[0].id,
      personaID: value.persona_id,
      isStudio: value.is_studio,
      createdAt: value.created_at,
      updatedAt: value.updated_at ?? undefined,
      memory: value.memory ?? undefined
    };
  }
  private normalizeGetPageOptions(options?: DeepPartial<GetPageOptions>): GetPageOptions {
    return {
      page: options?.page ?? 0,
      pageSize: options?.pageSize ?? 30,
      order: options?.order ?? "desc",
      filter: {
        searchText: options?.filter?.searchText ?? "",
        isStudio: options?.filter?.isStudio ?? false
      }
    };
  }
}
