/**
 * A wrapped tooltip component that makes tooltips work on both mobile and desktop.
 */
import { Tooltip, TooltipContent, TooltipTrigger } from "@moe/oss/ui/tooltip";
import { cn } from "@moe/priv/utils";
import { useEffect, useState } from "react";

export const toolTipDelay = 600;

// IMPORTANT:
// This tooltip will only work if you have the TooltipProvider component wrapping everything at the root of your app.
interface Props {
  text: string;
  children: React.ReactNode;
  className?: string;
  stopPropagation?: boolean;
  align?: "start" | "center" | "end";
  alignOffset?: number;
  [key: string]: any;
}

export function Tip({ text, children, className, stopPropagation, align, alignOffset, ...rest }: Props) {
  const [open, setOpen] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (shouldShow) {
      timer = setTimeout(() => setOpen(true), toolTipDelay);
    } else {
      setOpen(false);
    }
    return () => clearTimeout(timer);
  }, [shouldShow]);

  const handleClick = (e: React.MouseEvent | React.TouchEvent) => {
    if (stopPropagation) e.stopPropagation();
    setOpen(!open);
  };

  return (
    <Tooltip open={open}>
      <TooltipTrigger asChild onClick={handleClick}>
        <button
          type="button"
          onPointerDownCapture={(e) => stopPropagation && e.stopPropagation()}
          className={cn("cursor-pointer", className)}
          onClick={handleClick}
          onMouseEnter={() => setShouldShow(true)}
          onMouseLeave={() => setShouldShow(false)}
          onKeyDown={(e) => {
            e.preventDefault();
            e.key === "Enter" && setShouldShow(!shouldShow);
          }}
          {...rest}
        >
          {children}
        </button>
      </TooltipTrigger>
      <TooltipContent align={align} alignOffset={alignOffset} className={!text ? "hidden" : ""}>
        <span className="">{text}</span>
      </TooltipContent>
    </Tooltip>
  );
}
