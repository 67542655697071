import { CharacterService } from "@moe/priv/types/types";
import { z } from "zod";

const { rankingSchema, periodSchema, orderSchema } = CharacterService;

export const PAGE_SIZE = 30;

export const searchSchema = z.object({
  ranking: rankingSchema.optional(),
  searchText: z.string().optional(),
  page: z.number().min(1).optional().catch(1),
  order: orderSchema.optional(),
  period: periodSchema.optional(),
  nsfwOK: z.boolean().optional(),
  tags: z.array(z.string()).optional()
});
