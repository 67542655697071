import { Enums } from "@moe/priv/types/sb-types";
/**
 * The core type definitions for a "character" in the app.
 */

export const characterConfig = {
  nameMinChars: 1,
  nameMaxChars: 256,

  titleMinChars: 1,
  titleMaxChars: 256,

  tagLineMinChars: 0,
  taglineMaxChars: 8192,

  descriptionMinChars: 0,
  descriptionMaxChars: 8192,

  greetingMinChars: 1,
  greetingMaxChars: 4096,

  definitionsMinChars: 0,
  definitionsMaxChars: 8192,

  msgExamplesMinChars: 0,
  msgExamplesMaxChars: 4096,

  avatarWidth: 400,
  avatarHeight: 600,
  avatarMaxFileSizeBytes: 3e6,

  bannerWidth: 1500,
  bannerHeight: 500,
  bannerMaxFileSizeBytes: 3e6,

  tagsMaxCount: 32,
  tagsRegex: /^[a-zA-Z0-9-_]+$/,
  tagsCleanRegex: /[^a-zA-Z0-9-_]/g
};

export interface Character {
  id: number;
  title: string;
  tagline: string;
  tags?: string[];
  messageCount: number;
  likeCount: number;
  isLiked: boolean;
  name: string;
  greeting: string;
  avatar: string;
  banner?: string;
  isNSFW: boolean;
  isForcedNSFW: boolean;
  isPrivate: boolean;
  isDeleted: boolean;
  createdBy: string;
  externalCreatedBy?: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CharacterInsert {
  name: string;
  title: string;
  tagline: string;
  description: string;
  greeting: string;
  definitions?: string;
  message_examples?: string;
  avatar: File;
  banner?: File;
  isNSFW?: boolean;
  isPrivate?: boolean;
  externalKind?: Enums<"cardexternalkind">;
  externalCreatedBy?: string;
  tags?: string[];
}

export interface CharacterUpdate {
  id: number;
  title?: string;
  tagline?: string;
  name?: string;
  description?: string;
  greeting?: string;
  definitions?: string;
  message_examples?: string;
  avatar?: File;
  banner?: File;
  isNSFW?: boolean;
  isPrivate?: boolean;
  deletedAt?: string;
}
