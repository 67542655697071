import { Enums } from "@moe/priv/types/sb-types";
import { ChatCompletionChunk } from "openai/resources";
import { z } from "zod";

export type DeepPartial<TObject> = TObject extends object
  ? {
      [P in keyof TObject]?: DeepPartial<TObject[P]>;
    }
  : TObject;

export interface DialogConfig {
  title: string;
  cancelLabel?: string;
  actionLabel: string;
  description: string;
  onCancel?: () => void;
  onAction: () => void;
}

export interface ModalConfig {
  content: React.ReactNode;
  headerDragOnly?: boolean;
  // Often useful for preventign the modal from closing when clicking outside
  // https://github.com/shadcn-ui/ui/issues/1712#issuecomment-1758661015
  onInteractOutside?: (e: Event) => void;
}

// =========================================================
//  Shared Types between API <-> Web
// These should be moved some where else later
// ===========================;==============================

export interface GenerationData {
  messageID: number;
  chatID: number;
  characterID: number;
  chunk: ChatCompletionChunk;
}

export interface RegenerationData {
  messageID: number;
  candidateID: number;
  chatID: number;
  characterID: number;
  chunk: ChatCompletionChunk;
}

export type RateLimitError = {
  kind: "rate-limit";
  message: string;
};
export type UnknownError = {
  kind: "unknown";
  message: string;
};
export type GenerationError = RateLimitError | UnknownError;

export interface ChatPreview {
  chatID: number;
  // The lastest character message's text
  text: string;
  // Name of the character who sent the latest message
  name: string;
  // URL of the character's avatar
  avatar?: URL;
}

export type SubscriptionKind = Enums<"subscriptionkind">;
export type SubscriptionTier = Enums<"subscriptiontier">;

// TODO: Move this to the /priv/character model
export namespace CharacterService {
  export interface TimeRange {
    start: string | undefined;
    end: string | undefined;
  }

  export const periodSchema = z.enum(["day", "week", "month", "year", "all"]);
  export type Period = z.infer<typeof periodSchema>;
  export const orderSchema = z.enum(["asc", "desc"]);
  export type Order = z.infer<typeof orderSchema>;
  export const rankingSchema = z.enum(["message_count", "hotness", "recency", "liked"]);
  export type Ranking = z.infer<typeof rankingSchema>;

  export const getPageOptionsSchema = z.object({
    page: z.number().nonnegative(),
    pageSize: z.number().nonnegative(),
    ranking: rankingSchema,
    order: orderSchema,
    filter: z.object({
      creator: z.string().optional(),
      externalCreator: z.string().optional(),
      period: periodSchema,
      nsfwOK: z.boolean(),
      searchText: z.string().max(256),
      tags: z.array(z.string()).optional()
    })
  });

  export type GetPageOptions = z.infer<typeof getPageOptionsSchema>;
  export interface GetLastPageOptions {
    pageSize: number;
    filter: {
      period: Period;
      nsfwOK: boolean;
      searchText: string;
      tags: string[];
    };
  }

  // Contains extra fields that are hidden from regular select()
  export interface UpdateFormCharacter {
    id: number;
    title: string;
    tagline: string;
    name: string;
    description: string;
    greeting: string;
    definitions: string;
    messageExamples: string;
    messageCount: number;
    avatar: string;
    banner?: string;
    isNSFW: boolean;
    isForcedNSFW: boolean;
    isPrivate: boolean;
  }

  //SYNC: Keep this in synced with the key of the same name in the config table
  export const unavailableOKThreshold = 1000;
}
