import { useKeyHeld } from "@moe/priv/hooks/use-key-held";
import { desktopMediaQuery, useMediaQuery } from "@moe/priv/hooks/use-media-query";
import { ProfileSettings } from "@moe/priv/model/profile";
import { DialogConfig, ModalConfig } from "@moe/priv/types/types";
import { Session } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { CharacterAccessor } from "@web/accessor/character";
import { ChatAccessor } from "@web/accessor/chat";
import { MessageAccessor } from "@web/accessor/message";
import { PersonaAccessor } from "@web/accessor/persona";
import { ProfileAccessor } from "@web/accessor/profile";
import { APISettings, useAPISettings } from "@web/hooks/use-api-settings";
import { useSettingsQuery } from "@web/hooks/use-settings-query";
import { config } from "@web/lib/config";
import { sb } from "@web/lib/supabase";
import { client } from "@web/lib/trpc";
import { modalEE } from "@web/route-services/root/ModalProvider";
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

export interface Access {
  chat: ChatAccessor;
  character: CharacterAccessor;
  message: MessageAccessor;
  persona: PersonaAccessor;
  profile: ProfileAccessor;
}

export interface AppContextProps {
  createDialog: (config: DialogConfig) => void;
  createModal: (config: ModalConfig) => void;
  closeModal: () => void;
  session: Session | undefined;
  settings: ProfileSettings | undefined;
  apiSettings: APISettings;
  access: Access;
  tags: string[];
  isDesktop: boolean;
  isDebug: boolean;
  doFocusExploreRef: React.MutableRefObject<boolean>;
}

const Context = createContext<AppContextProps>({} as AppContextProps);

export const AppContextProvider = ({
  setAlertConfig,
  children
}: {
  setAlertConfig: React.Dispatch<React.SetStateAction<DialogConfig | undefined>>;
  children: React.ReactNode;
}) => {
  const doFocusExploreRef = useRef<boolean>(false);
  const [isDebug, setIsDebug] = useState(() => {
    // Toggle dev tools in prod
    const saved = localStorage.getItem("isDebugMode");
    const parsed = saved ? (JSON.parse(saved) as boolean) : false;
    // Dev tools is enabled when either:
    // - We are in a development environment
    // - Or we're in prod and we have set the isDebugMode flag
    return parsed || config.env.dev;
  });

  const [session, setSession] = useState<Session>();

  // Attach .debug() to the global window object
  // this allows us to toggle dev tools in prod with window.debug()
  useEffect(() => {
    window.debug = () => {
      setIsDebug(!isDebug);
      localStorage.setItem("isDebugMode", JSON.stringify(!isDebug));
    };
    return () => {
      delete window.debug;
    };
  }, [isDebug]);

  // Update session information
  useEffect(() => {
    sb.auth.getSession().then(({ data: { session } }) => {
      setSession(session ?? undefined);
    });
    const {
      data: { subscription }
    } = sb.auth.onAuthStateChange((_event, session) => {
      setSession(session ?? undefined);
    });
    return () => subscription.unsubscribe();
  }, []);

  const isShiftHeld = useKeyHeld("Shift");
  const isDesktop = useMediaQuery(desktopMediaQuery);
  const settings = useSettingsQuery(session);
  const apiSettings = useAPISettings();

  const { data: tags = [] } = useQuery({
    queryKey: ["getTags"],
    queryFn: async () => {
      return access.character.getTags();
    },
    // 1 Day
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    meta: { persist: true }
  });
  const access = useMemo(() => {
    const userID = session?.user.id;
    const chat = new ChatAccessor({ userID, trpc: client });
    const character = new CharacterAccessor({ userID, trpc: client });
    const message = new MessageAccessor({ userID });
    const persona = new PersonaAccessor({ userID });
    const profile = new ProfileAccessor({ userID });
    return {
      chat,
      character,
      message,
      persona,
      profile
    };
  }, [session?.user.id]);

  const appContextValue: AppContextProps = {
    createDialog: (config) => {
      if (isShiftHeld) {
        config.onAction();
        return;
      }
      setAlertConfig(config);
    },
    createModal: (config) => {
      modalEE.emit("createModal", config);
    },
    closeModal: () => {
      modalEE.emit("closeModal");
    },
    session,
    settings,
    apiSettings,
    access,
    tags,
    isDesktop,
    isDebug,
    doFocusExploreRef
  };

  return <Context.Provider value={appContextValue}>{children}</Context.Provider>;
};

export function useAppContext() {
  const context = useContext(Context);
  if (context === undefined) throw new Error("useApp must be used within an App Provider");
  return context;
}
