/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root"
import { Route as TosImport } from "./routes/tos"
import { Route as RecentChatsImport } from "./routes/recent-chats"
import { Route as ProfileImport } from "./routes/profile"
import { Route as PrivacyImport } from "./routes/privacy"
import { Route as DevImport } from "./routes/dev"
import { Route as ChatImport } from "./routes/chat"
import { Route as IndexImport } from "./routes/index"
import { Route as StudioUpdateImport } from "./routes/studio/update"
import { Route as StudioImportImport } from "./routes/studio/import"
import { Route as StudioCreateImport } from "./routes/studio/create"
import { Route as SettingsResetPasswordImport } from "./routes/settings/reset-password"
import { Route as SettingsProfileImport } from "./routes/settings/profile"
import { Route as SettingsPersonaImport } from "./routes/settings/persona"
import { Route as SettingsAccountImport } from "./routes/settings/account"

// Create/Update Routes

const TosRoute = TosImport.update({
  id: "/tos",
  path: "/tos",
  getParentRoute: () => rootRoute,
} as any)

const RecentChatsRoute = RecentChatsImport.update({
  id: "/recent-chats",
  path: "/recent-chats",
  getParentRoute: () => rootRoute,
} as any)

const ProfileRoute = ProfileImport.update({
  id: "/profile",
  path: "/profile",
  getParentRoute: () => rootRoute,
} as any)

const PrivacyRoute = PrivacyImport.update({
  id: "/privacy",
  path: "/privacy",
  getParentRoute: () => rootRoute,
} as any)

const DevRoute = DevImport.update({
  id: "/dev",
  path: "/dev",
  getParentRoute: () => rootRoute,
} as any)

const ChatRoute = ChatImport.update({
  id: "/chat",
  path: "/chat",
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any)

const StudioUpdateRoute = StudioUpdateImport.update({
  id: "/studio/update",
  path: "/studio/update",
  getParentRoute: () => rootRoute,
} as any)

const StudioImportRoute = StudioImportImport.update({
  id: "/studio/import",
  path: "/studio/import",
  getParentRoute: () => rootRoute,
} as any)

const StudioCreateRoute = StudioCreateImport.update({
  id: "/studio/create",
  path: "/studio/create",
  getParentRoute: () => rootRoute,
} as any)

const SettingsResetPasswordRoute = SettingsResetPasswordImport.update({
  id: "/settings/reset-password",
  path: "/settings/reset-password",
  getParentRoute: () => rootRoute,
} as any)

const SettingsProfileRoute = SettingsProfileImport.update({
  id: "/settings/profile",
  path: "/settings/profile",
  getParentRoute: () => rootRoute,
} as any)

const SettingsPersonaRoute = SettingsPersonaImport.update({
  id: "/settings/persona",
  path: "/settings/persona",
  getParentRoute: () => rootRoute,
} as any)

const SettingsAccountRoute = SettingsAccountImport.update({
  id: "/settings/account",
  path: "/settings/account",
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/"
      path: "/"
      fullPath: "/"
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    "/chat": {
      id: "/chat"
      path: "/chat"
      fullPath: "/chat"
      preLoaderRoute: typeof ChatImport
      parentRoute: typeof rootRoute
    }
    "/dev": {
      id: "/dev"
      path: "/dev"
      fullPath: "/dev"
      preLoaderRoute: typeof DevImport
      parentRoute: typeof rootRoute
    }
    "/privacy": {
      id: "/privacy"
      path: "/privacy"
      fullPath: "/privacy"
      preLoaderRoute: typeof PrivacyImport
      parentRoute: typeof rootRoute
    }
    "/profile": {
      id: "/profile"
      path: "/profile"
      fullPath: "/profile"
      preLoaderRoute: typeof ProfileImport
      parentRoute: typeof rootRoute
    }
    "/recent-chats": {
      id: "/recent-chats"
      path: "/recent-chats"
      fullPath: "/recent-chats"
      preLoaderRoute: typeof RecentChatsImport
      parentRoute: typeof rootRoute
    }
    "/tos": {
      id: "/tos"
      path: "/tos"
      fullPath: "/tos"
      preLoaderRoute: typeof TosImport
      parentRoute: typeof rootRoute
    }
    "/settings/account": {
      id: "/settings/account"
      path: "/settings/account"
      fullPath: "/settings/account"
      preLoaderRoute: typeof SettingsAccountImport
      parentRoute: typeof rootRoute
    }
    "/settings/persona": {
      id: "/settings/persona"
      path: "/settings/persona"
      fullPath: "/settings/persona"
      preLoaderRoute: typeof SettingsPersonaImport
      parentRoute: typeof rootRoute
    }
    "/settings/profile": {
      id: "/settings/profile"
      path: "/settings/profile"
      fullPath: "/settings/profile"
      preLoaderRoute: typeof SettingsProfileImport
      parentRoute: typeof rootRoute
    }
    "/settings/reset-password": {
      id: "/settings/reset-password"
      path: "/settings/reset-password"
      fullPath: "/settings/reset-password"
      preLoaderRoute: typeof SettingsResetPasswordImport
      parentRoute: typeof rootRoute
    }
    "/studio/create": {
      id: "/studio/create"
      path: "/studio/create"
      fullPath: "/studio/create"
      preLoaderRoute: typeof StudioCreateImport
      parentRoute: typeof rootRoute
    }
    "/studio/import": {
      id: "/studio/import"
      path: "/studio/import"
      fullPath: "/studio/import"
      preLoaderRoute: typeof StudioImportImport
      parentRoute: typeof rootRoute
    }
    "/studio/update": {
      id: "/studio/update"
      path: "/studio/update"
      fullPath: "/studio/update"
      preLoaderRoute: typeof StudioUpdateImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute
  "/chat": typeof ChatRoute
  "/dev": typeof DevRoute
  "/privacy": typeof PrivacyRoute
  "/profile": typeof ProfileRoute
  "/recent-chats": typeof RecentChatsRoute
  "/tos": typeof TosRoute
  "/settings/account": typeof SettingsAccountRoute
  "/settings/persona": typeof SettingsPersonaRoute
  "/settings/profile": typeof SettingsProfileRoute
  "/settings/reset-password": typeof SettingsResetPasswordRoute
  "/studio/create": typeof StudioCreateRoute
  "/studio/import": typeof StudioImportRoute
  "/studio/update": typeof StudioUpdateRoute
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute
  "/chat": typeof ChatRoute
  "/dev": typeof DevRoute
  "/privacy": typeof PrivacyRoute
  "/profile": typeof ProfileRoute
  "/recent-chats": typeof RecentChatsRoute
  "/tos": typeof TosRoute
  "/settings/account": typeof SettingsAccountRoute
  "/settings/persona": typeof SettingsPersonaRoute
  "/settings/profile": typeof SettingsProfileRoute
  "/settings/reset-password": typeof SettingsResetPasswordRoute
  "/studio/create": typeof StudioCreateRoute
  "/studio/import": typeof StudioImportRoute
  "/studio/update": typeof StudioUpdateRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  "/": typeof IndexRoute
  "/chat": typeof ChatRoute
  "/dev": typeof DevRoute
  "/privacy": typeof PrivacyRoute
  "/profile": typeof ProfileRoute
  "/recent-chats": typeof RecentChatsRoute
  "/tos": typeof TosRoute
  "/settings/account": typeof SettingsAccountRoute
  "/settings/persona": typeof SettingsPersonaRoute
  "/settings/profile": typeof SettingsProfileRoute
  "/settings/reset-password": typeof SettingsResetPasswordRoute
  "/studio/create": typeof StudioCreateRoute
  "/studio/import": typeof StudioImportRoute
  "/studio/update": typeof StudioUpdateRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | "/"
    | "/chat"
    | "/dev"
    | "/privacy"
    | "/profile"
    | "/recent-chats"
    | "/tos"
    | "/settings/account"
    | "/settings/persona"
    | "/settings/profile"
    | "/settings/reset-password"
    | "/studio/create"
    | "/studio/import"
    | "/studio/update"
  fileRoutesByTo: FileRoutesByTo
  to:
    | "/"
    | "/chat"
    | "/dev"
    | "/privacy"
    | "/profile"
    | "/recent-chats"
    | "/tos"
    | "/settings/account"
    | "/settings/persona"
    | "/settings/profile"
    | "/settings/reset-password"
    | "/studio/create"
    | "/studio/import"
    | "/studio/update"
  id:
    | "__root__"
    | "/"
    | "/chat"
    | "/dev"
    | "/privacy"
    | "/profile"
    | "/recent-chats"
    | "/tos"
    | "/settings/account"
    | "/settings/persona"
    | "/settings/profile"
    | "/settings/reset-password"
    | "/studio/create"
    | "/studio/import"
    | "/studio/update"
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ChatRoute: typeof ChatRoute
  DevRoute: typeof DevRoute
  PrivacyRoute: typeof PrivacyRoute
  ProfileRoute: typeof ProfileRoute
  RecentChatsRoute: typeof RecentChatsRoute
  TosRoute: typeof TosRoute
  SettingsAccountRoute: typeof SettingsAccountRoute
  SettingsPersonaRoute: typeof SettingsPersonaRoute
  SettingsProfileRoute: typeof SettingsProfileRoute
  SettingsResetPasswordRoute: typeof SettingsResetPasswordRoute
  StudioCreateRoute: typeof StudioCreateRoute
  StudioImportRoute: typeof StudioImportRoute
  StudioUpdateRoute: typeof StudioUpdateRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ChatRoute: ChatRoute,
  DevRoute: DevRoute,
  PrivacyRoute: PrivacyRoute,
  ProfileRoute: ProfileRoute,
  RecentChatsRoute: RecentChatsRoute,
  TosRoute: TosRoute,
  SettingsAccountRoute: SettingsAccountRoute,
  SettingsPersonaRoute: SettingsPersonaRoute,
  SettingsProfileRoute: SettingsProfileRoute,
  SettingsResetPasswordRoute: SettingsResetPasswordRoute,
  StudioCreateRoute: StudioCreateRoute,
  StudioImportRoute: StudioImportRoute,
  StudioUpdateRoute: StudioUpdateRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/chat",
        "/dev",
        "/privacy",
        "/profile",
        "/recent-chats",
        "/tos",
        "/settings/account",
        "/settings/persona",
        "/settings/profile",
        "/settings/reset-password",
        "/studio/create",
        "/studio/import",
        "/studio/update"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/chat": {
      "filePath": "chat.tsx"
    },
    "/dev": {
      "filePath": "dev.tsx"
    },
    "/privacy": {
      "filePath": "privacy.tsx"
    },
    "/profile": {
      "filePath": "profile.tsx"
    },
    "/recent-chats": {
      "filePath": "recent-chats.tsx"
    },
    "/tos": {
      "filePath": "tos.tsx"
    },
    "/settings/account": {
      "filePath": "settings/account.tsx"
    },
    "/settings/persona": {
      "filePath": "settings/persona.tsx"
    },
    "/settings/profile": {
      "filePath": "settings/profile.tsx"
    },
    "/settings/reset-password": {
      "filePath": "settings/reset-password.tsx"
    },
    "/studio/create": {
      "filePath": "studio/create.tsx"
    },
    "/studio/import": {
      "filePath": "studio/import.tsx"
    },
    "/studio/update": {
      "filePath": "studio/update.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
